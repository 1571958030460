import { sendUserInfo } from '../../util/api';
// ================ Action types ================ //

export const SEND_INFO_REQUEST = 'app/ProfileBConsPage/SEND_INFO_REQUEST';
export const SEND_INFO_SUCCESS = 'app/ProfileBConsPage/SEND_INFO_SUCCESS';
export const SEND_INFO_ERROR = 'app/ProfileBConsPage/SEND_INFO_ERROR';

// ================ Reducer ================ //

const initialState = {
  sendInfoInProgress: null,
  sendInfoInSuccess: null,
  sendInfoError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SEND_INFO_REQUEST:
      return { ...state, sendInfoError: null, sendInfoInProgress: true };
    case SEND_INFO_SUCCESS:
      return { ...state, sendInfoInProgress: null, sendInfoInSuccess: true };
    case SEND_INFO_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        sendInfoError: payload,
        sendInfoInProgress: null,
        sendInfoInSuccess: null,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const sendInfoRequest = e => ({ type: SEND_INFO_REQUEST });
export const sendInfoSuccess = () => ({ type: SEND_INFO_SUCCESS });
export const sendInfoError = e => ({
  type: SEND_INFO_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const sendInfo = params => (dispatch, getState, sdk) => {
  dispatch(sendInfoRequest());
  return sendUserInfo(params)
    .then(response => {
      console.log(response);
      dispatch(sendInfoSuccess());
      return response;
    })
    .catch(e => dispatch(sendInfoError(e)));
};
