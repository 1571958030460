import pick from 'lodash/pick';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { fetchCurrentUserReferrals } from '../../util/api';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ReferralPage/SET_INITIAL_VALUES';
export const FETCH_REFERRALS_REQUEST = 'app/ReferralPage/FETCH_REFERRALS_REQUEST';
export const FETCH_REFERRALS_SUCCESS = 'app/ReferralPage/FETCH_REFERRALS_SUCCESS';
export const FETCH_REFERRALS_ERROR = 'app/ReferralPage/FETCH_REFERRALS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchReferralsInProgress: false,
  fetchReferralsError: null,
  referrals: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_REFERRALS_REQUEST:
      return { ...state, fetchReferralsInProgress: true };
    case FETCH_REFERRALS_ERROR:
      return { ...state, fetchReferralsInProgress: false, fetchReferralsError: payload };
    case FETCH_REFERRALS_SUCCESS:
      return { ...state, fetchReferralsInProgress: false, referrals: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchReferralsRequest = () => ({
  type: FETCH_REFERRALS_REQUEST,
});
export const fetchReferralsRequestError = e => ({
  type: FETCH_REFERRALS_ERROR,
  error: true,
  payload: e,
});
export const fetchReferralsRequestSuccess = payload => ({
  type: FETCH_REFERRALS_SUCCESS,
  payload: payload,
});

// ================ Thunks ================ //

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());
  dispatch(fetchReferralsRequest());

  return fetchCurrentUserReferrals({})
    .then(response => {
      dispatch(fetchReferralsRequestSuccess(response));
      return response;
    })
    .catch((e) => dispatch(fetchReferralsRequestError(e)));
};
