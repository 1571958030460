import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './LanguageSelect.module.css';
import Select from 'react-select'
import { useLocation } from 'react-router-dom';
import { matchPathname } from '../../util/routes.js';
import { useRouteConfiguration } from '../../context/routeConfigurationContext.js';
import { useConfiguration } from '../../context/configurationContext';
import { callLoadData } from '../../routing/Routes';
import { useDispatch } from 'react-redux'

import fr from "./icons/fr.png";
import us from "./icons/us.png";
import nl from "./icons/nl.png";

const STATIC_PAGES = ['LandingPage', 'CMSPage', 'PrivacyPolicyPage', 'TermsOfServicePage']

const LanguageSelect = props => {
  const { className, rootClassName, onLanguageChange, isMobile, intl } = props;
  const classes = classNames(rootClassName || css.root, className);
  const routes = useRouteConfiguration();
  const location = useLocation();
  const matchedRoutes = matchPathname(location.pathname, routes);
  const match = matchedRoutes[0];
  const route = match?.route;
  const isStaticPage = STATIC_PAGES.includes(match?.route?.name)
  const dispatch = useDispatch();
  const config = useConfiguration();
  const handleLangChange = async (e) => {
    if (isStaticPage) {
      const res = await onLanguageChange(e.value)
      callLoadData({match, location, route, dispatch, logoutInProgress: false, config})
    } else {
      onLanguageChange(e.value);
    }
  };
  const options = [
    { value: "fr", label: "French", image: fr},
    { value: "en", label: "English", image: us},
    { value: "nl", label: "Dutch", image: nl},
  ]

  const currentLocale = intl.locale;
  const langIcon = options.find(o => o.value === currentLocale )?.image;

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: 'none',
      boxShadow: "none",
      background: 'none',
      margin: "12px 0",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0
    }),
    menu: (provided) => ({
      ...provided,
      width: 200,
      marginTop: isMobile ? 10 : 0,
      marginBottom: 0,
      right: 0,
    }),
    option: (provided, state) => {
      return {
        ...provided,
        paddingLeft: 24,
        paddingRight: 24,
        borderLeft: state.data.value === intl.locale ? '3px solid #115efb' : 'none',
      }
    },
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    })
  }

  return (
    <div className={classes}>
      <Select
        // defaultMenuIsOpen={true}
        className={css.select}
        isSearchable={false}
        styles={customStyles}
        options={options}
        value={options[0]}
        formatOptionLabel={options => (
          <div className={options.label ? css.spacedLabel : css.unspacedLabel}>
            {options.image ? <img src={options.image} alt="country" /> : null}
            <span>{options.label}</span>
          </div>
        )}
        onChange={handleLangChange}
        value={{ label: null, image: langIcon }}
      />
    </div>
  );
};

const { string } = PropTypes;

LanguageSelect.defaultProps = {
  className: null,
  rootClassName: null,
};

LanguageSelect.propTypes = {
  className: string,
  rootClassName: string,
};

export default LanguageSelect;
