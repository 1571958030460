import pick from 'lodash/pick';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { fetchCurrentUserReferralCode } from '../../util/api';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ReferralPage/SET_INITIAL_VALUES';
export const FETCH_REFERRAL_REQUEST = 'app/ReferralPage/FETCH_REFERRAL_REQUEST';
export const FETCH_REFERRAL_SUCCESS = 'app/ReferralPage/FETCH_REFERRAL_SUCCESS';
export const FETCH_REFERRAL_ERROR = 'app/ReferralPage/FETCH_REFERRAL_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchReferralInProgress: false,
  fetchReferralError: null,
  referral: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_REFERRAL_REQUEST:
      return { ...state, fetchReferralInProgress: true };
    case FETCH_REFERRAL_ERROR:
      return { ...state, fetchReferralInProgress: false, fetchReferralError: payload };
    case FETCH_REFERRAL_SUCCESS:
      return { ...state, fetchReferralInProgress: false, referral: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchReferralRequest = () => ({
  type: FETCH_REFERRAL_REQUEST,
});
export const fetchReferralRequestError = e => ({
  type: FETCH_REFERRAL_ERROR,
  error: true,
  payload: e,
});
export const fetchReferralRequestSuccess = payload => ({
  type: FETCH_REFERRAL_SUCCESS,
  payload: payload,
});

// ================ Thunks ================ //

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());
  dispatch(fetchReferralRequest());

  return fetchCurrentUserReferralCode({})
    .then(response => {
      dispatch(fetchReferralRequestSuccess(response.referral));
      return response;
    })
    .catch((e) => dispatch(fetchReferralRequestError(e)));
};
