import React from 'react';
import { useConfiguration } from '../../context/configurationContext';
import loadable from '@loadable/component';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { denormalizeAssetData } from '../../util/data';

const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

const FooterComponent = props => {
  let { footer = {} } = useConfiguration();
  const { locale } = props;

  let localData = null
  try {
    localData = require(`../../assets/content/pages/${locale}/footer.json`)
    const data = {data: localData.data[0]?.attributes?.data}
    footer = denormalizeAssetData(data);
  } catch (e) {
  }

  // If footer asset is not set, let's not render Footer at all.
  if (Object.keys(footer).length === 0) {
    return null;
  }

  // The footer asset does not specify sectionId or sectionType. However, the SectionBuilder
  // expects sectionId and sectionType in order to identify the section. We add those
  // attributes here before passing the asset to SectionBuilder.
  const footerSection = {
    ...footer,
    sectionId: 'footer',
    sectionType: 'footer',
  };

  return <SectionBuilder sections={[footerSection]} />;
};

// NOTE: if you want to add dynamic data to FooterComponent,
//       you could just connect this FooterContainer to Redux Store
//
const mapStateToProps = state => {
  const {
    currentUser,
  } = state.user;
  const { locale } = state.ui;
  const userLocale = currentUser?.attributes?.profile?.privateData?.language;
  return { locale: userLocale ||  locale};
};

const FooterContainer = compose(connect(mapStateToProps))(FooterComponent);

export default FooterContainer;

