import config from '../config/configDefault';
import moment from 'moment';
import { updateProfile } from '../containers/ProfileSettingsPage/ProfileSettingsPage.duck'

// ================ Action types ================ //

export const DISABLE_SCROLLING = 'app/ui/DISABLE_SCROLLING';
export const CHANGE_LANGUAGE = 'app/ui/CHANGE_LANGUAGE';

// ================ Reducer ================ //

const initialState = {
  disableScrollRequests: [],
  locale: config.localization.locale,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case DISABLE_SCROLLING: {
      const { componentId, disableScrolling } = payload;
      const disableScrollRequests = state.disableScrollRequests;
      const componentIdExists = disableScrollRequests.find(c => c.componentId === componentId);

      if (componentIdExists) {
        const disableScrollRequestArray = disableScrollRequests.map(c => {
          return c.componentId === componentId ? { ...c, disableScrolling } : c;
        });
        return { ...state, disableScrollRequests: [...disableScrollRequestArray] };
      }

      const disableScrollRequestArray = [
        ...disableScrollRequests,
        { componentId, disableScrolling },
      ];
      return {
        ...state,
        disableScrollRequests: disableScrollRequestArray,
      };
    }

    case CHANGE_LANGUAGE: {
      const { locale } = payload;
      moment.locale(locale);
      return { ...state, locale: locale };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const manageDisableScrolling = (componentId, disableScrolling) => ({
  type: DISABLE_SCROLLING,
  payload: { componentId, disableScrolling },
});

export const changeUiLanguage = (locale) => ({
  type: CHANGE_LANGUAGE,
  payload: { locale },
});

// ================ Selectors ================ //

export const isScrollingDisabled = state => {
  const { disableScrollRequests } = state.ui;
  return disableScrollRequests.some(r => r.disableScrolling);
};


export const changeLanguage = (locale) => (dispatch, getState, sdk) => {
  dispatch(changeUiLanguage(locale));
  const params = {privateData: {language: locale}};
  const { currentUser } = getState().user;
  
  if (!!currentUser){
    return dispatch(updateProfile(params))
      .then((res) => {})
      .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
  }
};
