import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCheck.module.css';

const IconCheck = props => {
  const { rootClassName, className, size } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} strokeWidth="2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
      <g><g><g><circle className={css.circle} cx="4" cy="4" r="4"/><path className={css.path} d="M3.29,5.73l-1.11-1.13s-.04-.11,0-.15l.58-.58s.11-.04,.15,0l.38,.39s.11,.04,.15,0l1.65-1.65s.11-.04,.15,0l.58,.58s.04,.11,0,.15l-2.39,2.39s-.11,.04-.15,0Z"/></g></g></g>
    </svg>
  )
};

IconCheck.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCheck.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCheck;
