import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from './../../util/reactIntl';
import difference from 'lodash/difference';
import moment from 'moment';

// Sharetribe Web Template uses English translations as default translations.
import defaultMessages from '../../translations/en.json';
import fr from '../../translations/fr.json';
import nl from '../../translations/nl.json';

const messages = {
  en: defaultMessages,
  fr: fr,
  nl: nl,
};

class ConnectedIntlProvider extends Component {
  render() {
    const { locale, hostedTranslations } = this.props;
    const messagesInLocale = messages[locale] || {};

    // If translation key is missing from `messagesInLocale` (e.g. fr.json),
    // corresponding key will be added to messages from `defaultMessages` (en.json)
    // to prevent missing translation key errors.
    const addMissingTranslations = (sourceLangTranslations, targetLangTranslations) => {
      const sourceKeys = Object.keys(sourceLangTranslations);
      const targetKeys = Object.keys(targetLangTranslations);
  
      // if there's no translations defined for target language, return source translations
      if (targetKeys.length === 0) {
        return sourceLangTranslations;
      }
      const missingKeys = difference(sourceKeys, targetKeys);
  
      const addMissingTranslation = (translations, missingKey) => ({
        ...translations,
        [missingKey]: sourceLangTranslations[missingKey],
      });
  
      return missingKeys.reduce(addMissingTranslation, targetLangTranslations);
    };
  
    // Get default messages for a given locale.
    //
    // Note: Locale should not affect the tests. We ensure this by providing
    //       messages with the key as the value of each message and discard the value.
    //       { 'My.translationKey1': 'My.translationKey1', 'My.translationKey2': 'My.translationKey2' }
    const isTestEnv = process.env.NODE_ENV === 'test';
    const localeMessages = isTestEnv
      ? mapValues(defaultMessages, (val, key) => key)
      : addMissingTranslations(defaultMessages, messagesInLocale);

    return <IntlProvider locale={locale} messages={{ ...hostedTranslations, ...localeMessages }} textComponent="span" >{this.props.children}</IntlProvider>
  }
}

function mapStateToProps(state) {
  const {
    currentUser,
  } = state.user;
  const { locale } = state.ui;
  const userLocale = currentUser?.attributes?.profile?.privateData?.language;
  const localeValue = userLocale ||  locale;
  moment.locale(localeValue);
  return { locale: localeValue };
}

export default connect(mapStateToProps)(ConnectedIntlProvider);
