import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconCheck, NamedLink, SecondaryButton } from '../../components';
import css from './Subscription.module.css';
import classNames from 'classnames';

export const SubscriptionComponent = props => {
  const {
    onSaveSubscription,
    onManageSubscription,
    subscription,
    createStripeSubscriptionInProgress,
    createStripeSubscriptionError,
    showListingErrorMessage,
    intl,
    hideLinks,
  } = props;

  const language = intl.locale;

  const plans = [
    // {
    //   name: 'platinum',
    //   header: intl.formatMessage({ id: 'Subscription.platinum' }),
    //   duration: intl.formatMessage({ id: 'Subscription.platinumDuration' }),
    //   period: 12,
    //   price: intl.formatMessage({ id: 'Subscription.platinumPrice' }),
    //   benefits: [intl.formatMessage({ id: 'Subscription.benefitPlatinum1' })],
    //   referral: {
    //     bonus: intl.formatMessage({ id: 'Subscription.platinumBonus' }),
    //     period: intl.formatMessage({ id: 'Subscription.platinumBonusPeriod' }),
    //   },
    //   ctaContent: intl.formatMessage({ id: 'Subscription.select' }),
    //   selectedCtaContent: intl.formatMessage({ id: 'Subscription.changeOrCancel' }),
    // },
    // {
    //   name: 'gold',
    //   header: intl.formatMessage({ id: 'Subscription.gold' }),
    //   duration: intl.formatMessage({ id: 'Subscription.goldDuration' }),
    //   period: 6,
    //   price: intl.formatMessage({ id: 'Subscription.goldPrice' }),
    //   benefits: [intl.formatMessage({ id: 'Subscription.benefitGold1' })],
    //   referral: {
    //     bonus: intl.formatMessage({ id: 'Subscription.goldBonus' }),
    //     period: intl.formatMessage({ id: 'Subscription.goldBonusPeriod' }),
    //   },
    //   ctaContent: intl.formatMessage({ id: 'Subscription.select' }),
    //   selectedCtaContent: intl.formatMessage({ id: 'Subscription.changeOrCancel' }),
    // },
    {
      name: 'silver',
      header: intl.formatMessage({ id: 'Subscription.silver' }),
      duration: intl.formatMessage({ id: 'Subscription.silverDuration' }),
      period: 1,
      price: intl.formatMessage({ id: 'Subscription.silverPrice' }),
      benefits: [intl.formatMessage({ id: 'Subscription.benefitSilver1' })],
      referral: {
        bonus: intl.formatMessage({ id: 'Subscription.silverBonus' }),
        period: intl.formatMessage({ id: 'Subscription.silverBonusPeriod' }),
      },
      ctaContent: intl.formatMessage({ id: 'Subscription.select' }),
      selectedCtaContent: intl.formatMessage({ id: 'Subscription.changeOrCancel' }),
    },
  ];

  return (
    <section className={css.subscriptionSection}>
      {!hideLinks && (
        <>
          <p>{intl.formatMessage({ id: 'Subscription.descriptionLine1' })}</p>
          <p>
            {intl.formatMessage(
              { id: 'Subscription.manageSubscriptions' },
              {
                here: (
                  <a onClick={() => onManageSubscription({ language })}>
                    {intl.formatMessage({ id: 'Subscription.manageSubscriptionsLink' })}
                  </a>
                ),
              }
            )}
          </p>
        </>
      )}

      <div className={css.subscriptions}>
        {plans.map((plan, i) => (
          <div
            key={i}
            className={classNames(css.plan, {
              [css.currentPlan]: subscription === plan.name,
            })}
            data-subscription={plan.name}
          >
            <div className={css.header}>
              <div className={css.duration}>{plan.duration}</div>
              <div className={css.heading}>
                <span>
                  {subscription === plan.name && (
                    <div className={css.currentPlanCheck}>
                      <IconCheck />
                    </div>
                  )}
                </span>
                <span>
                  {plan.header}
                </span>
              </div>
            </div>
            <div className={css.price}>
              <div className={css.priceWrapper}>
                <span className={css.currency}>
                  {intl.formatMessage({ id: 'Subscription.currency' })}
                </span>
                <span>{plan.price}</span>
                <span className={css.cents}>
                  {intl.formatMessage({ id: 'Subscription.99cents' })}
                </span>
              </div>
              <div className={css.durationWrapper}>
                <div className={css.perMonth}>
                  {intl.formatMessage({ id: 'Subscription.duration' })}
                </div>
                <div className={css.period}>
                  {intl.formatMessage({ id: 'Subscription.period' }, { period: plan.period })}
                </div>
              </div>
            </div>
            <hr />
            <div className={css.details}>
              {plan.benefits.map((benefit, j) => (
                <div key={j}>
                  {benefit}
                </div>
              ))}
            </div>

            <div className={css.referral}>
              <h3 className={css.referralHeading}>
                {intl.formatMessage({ id: 'Subscription.referralSystem' })}
              </h3>
              <hr />
              <div>
                <IconCheck />
                <span className={css.bonus}>{plan.referral.bonus}</span>
                <span className={css.bonusCurrency}>
                  {intl.formatMessage({ id: 'Subscription.currency' })}
                </span>
                <span className={css.bonusPeriod}>{plan.referral.period}</span>
                &nbsp;<sup>*</sup>
              </div>
            </div>

            <footer>
              <div className={css.note}>
                <sup>*</sup>
                {intl.formatMessage(
                  { id: 'Subscription.note' },
                  {
                    withoutLimit: (
                      <span className={css.highlight}>
                        {intl.formatMessage({ id: 'Subscription.withoutLimit' })}
                      </span>
                    ),
                  }
                )}
              </div>

              {!!onSaveSubscription && (
                <div className={css.ctaContainer}>
                  <SecondaryButton
                    inProgress={createStripeSubscriptionInProgress === plan.name}
                    className={css.ctaButton}
                    onClick={() => onSaveSubscription({ subscription: plan.name, language })}
                  >
                    {subscription === plan.name ? plan.selectedCtaContent : plan.ctaContent}
                  </SecondaryButton>
                </div>
              )}
            </footer>
          </div>
        ))}
      </div>
      {createStripeSubscriptionError && (
        <div className={css.error}>
          {intl.formatMessage({ id: 'Subscription.subscriptionError' })}
        </div>
      )}
    </section>
  );
};

SubscriptionComponent.defaultProps = {
  showListingErrorMessage: true,
};

SubscriptionComponent.propTypes = {
  showListingErrorMessage: PropTypes.bool,
  onSaveSubscription: PropTypes.func,
};

export default SubscriptionComponent;
