/**
 * Transaction process graph for plain inquiries:
 *   - default-inquiry
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // A customer can initiate a transaction with an inquiry, and
  // then transition that with a request.
  APPLY: 'transition/apply',
  ACCEPT: 'transition/accept',
  REJECT: 'transition/reject',
  REQUEST_APPROVAL: 'transition/request-approval',
  COMPLETE: 'transition/complete',
  REJECT_APPROVAL_REQUEST: 'transition/reject-approval-request',

  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REJECTED_REVIEW_1_BY_PROVIDER: 'transition/rejected-review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REJECTED_REVIEW_1_BY_CUSTOMER: 'transition/rejected-review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  APPLIED: 'applied',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  APPROVAL_REQUESTED: 'approval-requested',
  COMPLETED: 'completed',
  APPROVAL_REQUEST_REJECTED: 'approval-request-rejected',
  REVIEWED: 'reviewed',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-inquiry/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.APPLY]: states.APPLIED,
      },
    },
    [states.APPLIED]: {
      on: {
        [transitions.ACCEPT]: states.ACCEPTED,
        [transitions.REJECT]: states.REJECTED,
      },
    },
    [states.ACCEPTED]: {
      on: {
        [transitions.REQUEST_APPROVAL]: states.APPROVAL_REQUESTED,
      },
    },
    [states.REJECTED]: {},
    [states.APPROVAL_REQUESTED]: {
      on: {
        [transitions.COMPLETE]: states.COMPLETED,
        [transitions.REJECT_APPROVAL_REQUEST]: states.APPROVAL_REQUEST_REJECTED,
      },
    },
    [states.APPROVAL_REQUEST_REJECTED]: {
      on: {
        [transitions.REJECTED_REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REJECTED_REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
      },
    },
    [states.COMPLETED]: {
      on: {
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
      },
    },

    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
      },
    },
    [states.REVIEWED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.APPLY,
    transitions.ACCEPT,
    transitions.REJECT,
    transitions.REQUEST_APPROVAL,
    transitions.COMPLETE,
    transitions.REJECT_APPROVAL_REQUEST,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REJECTED_REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REJECTED_REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REJECTED_REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REJECTED_REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
// NOTE: this functions is added just for the sake of consistency
export const isPrivileged = transition => {
  return false;
};

// Check when transaction is completed
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.COMPLETE,
    transitions.REJECT,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REJECTED_REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REJECTED_REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
  ];
  return txCompletedTransitions.includes(transition);
};
// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
// NOTE: this functions is added just for the sake of consistency
export const isRefunded = transition => {
  return false;
};

// NOTE: this functions is added just for the sake of consistency
// We don't know if inquiry is on-going or complete
export const statesNeedingProviderAttention = [
  states.APPLIED,
  states.APPROVAL_REQUESTED,
  states.COMPLETED,
  states.REVIEWED_BY_CUSTOMER,
];

export const statesNeedingCustomerAttention = [
  states.ACCEPTED,
  states.COMPLETED,
  states.REVIEWED_BY_PROVIDER,
];
