import { fetchPageAssets } from '../../ducks/hostedAssets.duck';

export const loadData = (params, search) => (
  dispatch,
  getState,
  sdk
) => {
  const pageId = params.pageId;
  const state = getState();
  const { locale } = state.ui || {};
  const {
    currentUser,
  } = state.user || {};
  const userLocale = currentUser?.attributes?.profile?.privateData?.language || locale;
  const pageAsset = { [pageId]: `content/pages/${pageId}.json` };
  const hasFallbackContent = false;
  return dispatch(fetchPageAssets(pageAsset, hasFallbackContent, userLocale));
};
