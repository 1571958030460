import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const ASSET_NAME = 'landing-page';

export const loadData = (params, search) => (
  dispatch,
  getState,
  sdk
) => {
  const state = getState();
  const { locale } = state.ui || {};
  const {
    currentUser,
  } = state.user || {};
  const userLocale = currentUser?.attributes?.profile?.privateData?.language || locale;
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true, userLocale));
};
